// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_2780 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 760px;

  @media only screen and (max-width: 990px) {
    height: 100vh;
  }

}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 350px;
  z-index: 1;
}

.backstretch {
  position: fixed !important;
  height: 100vh !important;

  // for top of page gradient
  &:before {
    @include before(100%, 633px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  &:after {
    @include before(100%, 493px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
  }

  img {

    top: 0px !important;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover !important;

    animation: zoom 30s ease forwards infinite;

    @keyframes zoom {
      from {
        transform: scale(1)
      }

      to {
        transform: scale(1.1)
      }
    }
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  // left: 0;
  right: 200px;
  top: 250px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 35px;
  font-weight: 400;

  @media only screen and (max-width: 1720px) {
    right: 130px;
  }

  @media only screen and (max-width: 1550px) {
    font-size: 30px;
    line-height: 1;
    right: 140px;
  }

  @media only screen and (max-width: 1400px) {
    font-size: 28px;
    right: 80px;
  }

  @media only screen and (max-width: 1350px) {
    font-size: 25px;
    right: 60px;
  }

  @media only screen and (max-width: 1180px) {
    left: 0;
    right: 0;
    top: 350px;
  }

  @media only screen and (max-width: 990px) {
    top: 490px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 350px) {
    font-size: 19px;
  }

  &:before {
    position: absolute;
    content: "";
    background: url(/i/design/cross.png);
    height: 39px;
    width: 25px;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  span {
    font-weight: 600;
  }
}

// -----------------------------------//
// Scrolling values
// -----------------------------------//


.scrolling-values {
  position: absolute;
  bottom: 170px;
  // left: 50%;
  // transform: translateX(-50%);
  left: 0;
  right: 0;
  text-align: center;

  @media only screen and (max-width: 990px) {
    top: 570px;
    bottom: auto;
  }



  h2 {
    color: #fff;
    font-size: 56px;
    text-shadow: -2.121px 2.121px 3px rgba(0, 0, 0, 0.4);
    line-height: 1.1;

    @media only screen and (max-width: 1350px) {
      font-size: 45px;
    }

    @media only screen and (max-width: 500px) {
      font-size: 35px;
    }

    @media only screen and (max-width: 350px) {
      font-size: 28px;
    }

    span {
      display: block;
      font-size: 35px;

      @media only screen and (max-width: 1350px) {
        font-size: 26px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 22px;
      }

      span {
        display: inline-block;
        color: #3E9CF0;
      }
    }
  }
}