section#social {
	width: 400px;
	height: 520px !important;
	position: fixed;
	top: calc(50% - 270px);
	left: auto;
	visibility: hidden;
	background: rgb(34, 115, 170);
	z-index: 80;
	border-radius: 20px 0 0 20px;

	iframe {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute !important;
		left: 20px;
		top: 20px;
	}

	@media only screen and (max-width: 1000px) {
		visibility: hidden !important;
		display: none !important;
	}

	// Right sidebar
	#sidebar {
		left: -45px;
		top: calc(50% - 75px);
		height: 210px;
		width: 45px;
		z-index: 40;
		position: absolute;
		transition: all 0.2s ease-out;
		cursor: pointer;
		background: rgb(34, 115, 170);
		box-shadow: 2.598px 1.5px 21px 0px rgba(0, 0, 0, 0.55);
		border-radius: 20px 0 0 20px;

		h2 {
			transform: rotate(90deg);
			text-align: center;
			position: absolute;
			left: -43px;
			top: 85px;
			color: #fff;
			font-size: 20px;
			white-space: nowrap;
			text-transform: uppercase;
			margin: 0;

			&:before {
				content: "";
				width: 14px;
				height: 14px;
				background: url(/i/design/tab-arrow.png) no-repeat center center;
				position: absolute;
				right: -25px;
				top: 8px;
				transform: rotate(90deg);
			}
		}

		&:hover {
			cursor: pointer;
		}

		/* Change the arrow  */
		.arrow h2::before {
			transform: rotate(-90deg) !important;
		}
	}
}